import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import LargeCarousel from "../global/largeCarousel";

const Section1 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                educationOverviewLargeCarousel {
                  ... on Craft_educationOverviewLargeCarousel_slide_BlockType {
                    image {
                      url(transform: "largeCarouselTransform")
                      ... on Craft_websiteEducationGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
              }
            }
          }
        }
  `)

    return (
        <LargeCarousel slides={data.craft.entry.educationOverviewLargeCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>
    )
}

export default Section1