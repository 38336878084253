import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import parse from "html-react-parser"
import "./section-2.scss"

const Section2 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                section2ContentTitle
                section2TextContentWithLink
              }
            }
          }
        }
  `)

    return (
        <div className="about-education blue-bg">
            <div className="container-small">
                <h3>{data.craft.entry.section2ContentTitle}</h3>
                {parse(data.craft.entry.section2TextContentWithLink)}
            </div>
        </div>
    )
}

export default Section2