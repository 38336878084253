import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Button from "../global/button";
import HeroImage from "../global/heroImage";
import parse from "html-react-parser"
import "./section-3.scss"

const Section3 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                educationHeroImage {
                  ... on Craft_educationHeroImage_image_BlockType {
                    image {
                      url(transform: "heroImageTransform")
                      ... on Craft_websiteEducationGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
                section3ContentTitle
                section3TextContentWithLink
                section3ButtonLink {
                  linkUrl
                  openInNewTab
                  linkText
                }
              }
            }
          }
        }
  `)

    return (
        <div className="hallway">
            <div className="container-small">
                <HeroImage image={data.craft.entry.educationHeroImage}/>
                <h3>{data.craft.entry.section3ContentTitle}</h3>
                {parse(data.craft.entry.section3TextContentWithLink)}
                <Button classes="btn-secondary" to={data.craft.entry.section3ButtonLink[0].linkUrl} text={data.craft.entry.section3ButtonLink[0].linkText} openInNewTab={data.craft.entry.section3ButtonLink[0].openInNewTab}/>
            </div>
        </div>
    )
}

export default Section3