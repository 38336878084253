import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Button from "../global/button";
import ImageLink from "../global/imageLink";
import parse from "html-react-parser"
import "./section-5.scss"

const Section5 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                educationOverviewSection5Image {
                  url(transform: "mediumHorizontal32AspectRatioTransform")
                  ... on Craft_websiteEducationGeneral_Asset {
                    altText
                  }
                }
                section5ImageLink {
                  linkUrl
                  openInNewTab
                }
                section5ContentTitle
                section5TextContentWithLink
                section5ButtonLink {
                  linkUrl
                  linkText
                  openInNewTab
                }
              }
            }
          }
        }
  `)

    return (
        <div className="school-music">
            <div className="container-small">
                <div className="humane-block">
                    <div className="humane-block-right">
                        <div className="film-img">
                            <ImageLink src={data.craft.entry.educationOverviewSection5Image[0].url} alt={data.craft.entry.educationOverviewSection5Image[0].altText} to={data.craft.entry.section5ImageLink[0].linkUrl} openInNewTab={data.craft.entry.section5ImageLink[0].openInNewTab}/>
                        </div>
                    </div>
                    <div className="humane-block-left">
                        <h3>{data.craft.entry.section5ContentTitle}</h3>
                        {parse(data.craft.entry.section5TextContentWithLink)}
                        <Button classes="btn-secondary" to={data.craft.entry.section5ButtonLink[0].linkUrl} text={data.craft.entry.section5ButtonLink[0].linkText} openInNewTab={data.craft.entry.section5ButtonLink[0].openInNewTab}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section5