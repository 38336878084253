import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Button from "../global/button";
import ImageLink from "../global/imageLink";
import parse from "html-react-parser"
import "./section-4.scss"

const Section4 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                section4ContentTitle
                section4TextContentWithLink
                section4ButtonLink {
                  linkUrl
                  openInNewTab
                  linkText
                }
                educationOverviewSection4Image {
                  url(transform: "mediumHorizontal32AspectRatioTransform")
                  ... on Craft_websiteEducationGeneral_Asset {
                    altText
                  }
                }
                section4ImageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
  `)

    return (
        <div className="humane-heroes blue-bg">
            <div className="container-small">
                <div className="humane-block">
                    <div className="humane-block-left">
                        <h3>{data.craft.entry.section4ContentTitle}</h3>
                        {parse(data.craft.entry.section4TextContentWithLink)}
                        <Button classes="btn-secondary" to={data.craft.entry.section4ButtonLink[0].linkUrl} text={data.craft.entry.section4ButtonLink[0].linkText} openInNewTab={data.craft.entry.section4ButtonLink[0].openInNewTab}/>
                    </div>
                    <div className="humane-block-right">
                        <div className="film-img">
                            <ImageLink src={data.craft.entry.educationOverviewSection4Image[0].url} alt={data.craft.entry.educationOverviewSection4Image[0].altText} to={data.craft.entry.section4ImageLink[0].linkUrl} openInNewTab={data.craft.entry.section4ImageLink[0].openInNewTab}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4