import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import InnerLayout from "../components/global/innerLayout";
import Section1 from "../components/education-overview/section-1";
import Section2 from "../components/education-overview/section-2";
import Section3 from "../components/education-overview/section-3";
import Section4 from "../components/education-overview/section-4";
import Section5 from "../components/education-overview/section-5";

const EducationPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            page: entry(section: "educationOverview") {
              ... on Craft_educationOverview_educationOverview_Entry {
                id
                title
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
          }
        }
  `)

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd education-overview">
                <Section1/>
                <Section2/>
                <Section3/>
                <Section4/>
                <Section5/>
            </div>
        </InnerLayout>
    )
}

export default EducationPage
